import * as React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"
const Index = (props) => {
    console.log(props)
  return (
      <Layout>
<div class="bg-white pb-6 pt-10 sm:pb-8  lg:pb-24 lg:pt-52">
  <div class="max-w-screen-2xl px-4 md:px-8 mx-auto">
    <div class="flex justify-between items-center gap-8 mb-4 sm:mb-8 md:mb-12">
      <div class="flex items-center gap-12">
        <h2 class="timing-ease-in-out change-time15  box fadeUp text-gray-800 text-4xl lg:text-5xl en ">Library</h2>
        <p class="timing-ease-in-out change-time1 delay-time05 box fadeUp max-w-screen-sm hidden md:block text-gray-500">Web制作に携わる以前のグラフィック・DTP時代も少し含めた制作実績、その他写真撮影やロゴ制作の提案書、また店舗内装デザインなども含めております。</p>
      </div>
    </div>

    <div class="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-6 xl:gap-8 " >

    {props.data.allContentfulGallery.edges.map((singleBlog, index) => (
    <div key={index}>
      <AniLink swipe direction="left"  top="entry"  entryOffset={100000} to={singleBlog.node.slug} class="galleries group h-48 md:h-64 xl:h-96 flex flex-col bg-gray-100 rounded-lg shadow-lg overflow-hidden relative">
        <GatsbyImage image={singleBlog.node.img.gatsbyImageData} alt="works_image" class="w-full h-full object-cover absolute object-center inset-0 group-hover:scale-110 transition duration-200"  />
        <div class="bg-gradient-to-t from-gray-800 md:via-transparent to-transparent absolute inset-0 pointer-events-none"></div>
        <div class="relative p-4 mt-auto">
          <span class="block text-gray-200 text-sm">{singleBlog.node.year}</span>
          <h2 class="text-white text-xl font-semibold transition duration-100 mb-2">{singleBlog.node.title}</h2>
          <span class="text-blue-400 font-semibold">More details</span>
        </div>
      </AniLink>
    </div>
    )
    )}

</div>

        
  </div>
</div>
      </Layout>
  )
}

export default Index

export const query = graphql`
query ContentfulBlogQuery {
    allContentfulGallery(sort: {fields: slug, order: DESC}) {
      edges {
        node {
            year
          title
          slug
          id
          img {
            gatsbyImageData(formats:  [AUTO, WEBP, AVIF], quality: 90, placeholder: BLURRED)
          }
        }
      }
    }
  }
`